<template>
  <div>
    <div class="d-flex align-items-center p-3">
      <select class="form-control select-category mr-2">
        <option v-for="(category, i) in categories" :key="i">{{ category }}</option>
      </select>

      <button class="p-2 btn" v-b-modal.modal-category>
        <img src="./images/icon-filter.svg" />
      </button>

      <b-modal
        id="modal-category"
        title-class="modal-category-title"
        title="商品分類"
        centered
      >
        <template #modal-header="{ close }">
          <h5 class="mb-0 w-100 text-center">商品分類</h5>
          <b-button size="sm" variant="link" class="close" @click="close()">
            <img src="./images/icon-close.svg" />
          </b-button>
        </template>

        <div class="row filters">
          <div
            class="col-6 mb-2"
            v-for="i in categories"
            :key="i"
            :style="colorObject"
          >
            <button class="btn btn-light rounded-pill w-100 p-2 filter">
              {{ i }}
            </button>
          </div>
        </div>

        <template #modal-footer="{ ok, cancel }">
          <div class="row w-100" :style="colorObject">
            <div class="col">
              <b-button
                size="lg"
                variant="outline-dark"
                class="w-100"
                @click="cancel()"
              >
                取消
              </b-button>
            </div>
            <div class="col">
              <b-button size="lg" variant="primary" class="w-100" @click="ok()">
                確認
              </b-button>
            </div>
          </div>
        </template>
      </b-modal>

      <button
        class="p-2 btn"
        @click="
          currentView =
            gridViews[(gridViews.indexOf(currentView) + 1) % gridViews.length]
        "
      >
        <img
          v-if="currentView == gridViews[0]"
          src="./images/icon-grid-2.svg"
        />
        <img
          v-if="currentView == gridViews[1]"
          src="./images/icon-grid-1.svg"
        />
        <img
          v-if="currentView == gridViews[2]"
          src="./images/icon-grid-list.svg"
        />
      </button>
    </div>

    <div class="spinner-positioner">
      <b-spinner variant="primary" v-show="products.length === 0"></b-spinner>
    </div>

    <div class="products" :class="currentView">
      <div class="products__item" v-for="p in products" :key="p.id">
        <div class="products__item__photo">
          <img :src="getImage(p)" class="img-fluid" v-if="getImage(p)" />
        </div>

        <div class="products__item__title product__title">{{ p.title }}</div>

        <div class="products__item__price">$ {{ p.price }}</div>

        <div class="products__item__description" v-html="p.description"></div>

        <div class="products__item__add-to-cart">
          <b-form-spinbutton
            min="0"
            :value="0"
            max="100"
            class="text-center px-0"
            style="border-radius: 0.5rem"
          ></b-form-spinbutton>
        </div>
      </div>
    </div>

    <footer class="sticky-bottom footer-action-bar">
      <div class="footer-action-bar__summary">
        <div class="footer-action-bar__text">共 <span class="footer-action-bar__text--emphasis">3</span> 件商品</div>
        <div class="footer-action-bar__text">總計 $ <span class="footer-action-bar__text--emphasis footer-action-bar__text--big">5,999</span></div>
      </div>
      <router-link
        :to="{ name: 'LiffPreorderCart' }"
        class="btn btn-primary btn-lg footer-action-bar__checkout-button"
      >
        下一步
      </router-link>
    </footer>
  </div>
</template>

<script>
import themeColor from "@/mixins/liff/themeColor";
import shopApi from "@/apis/liff/v2/shop";

const gridViews = ["grid-list", "grid-2", "grid-1"];

const categories = [
  "全部",
  "精品",
  "女性",
  "男性",
  "包包配件",
  "居家生活",
  "科技創意",
  "品味美食",
];

export default {
  mixins: [themeColor],
  data() {
    return {
      products: [],
      gridViews,
      categories,
      currentView: "grid-list",
    };
  },
  async mounted() {
    await this.init();
  },
  methods: {
    async init() {
      const data = await shopApi.getProducts();
      this.products = data.products.map((p) => ({
        ...p,
        description: p.description
          ? this.hiddenString(p.description.replace(/\n/g, "<br>"), 60)
          : null,
      }));
    },
    getImage(product) {
      return product.images[0]?.pic_url ?? false;
    },
    hiddenString(value, length) {
      value = value || "";
      const splittedValue = [...value];
      if (splittedValue.length <= length) {
        return value;
      }
      return splittedValue.slice(0, length).join("") + "...";
    },
  },
};
</script>

<style lang="scss" scoped>
.select-category {
  border-radius: 0.5rem;
  border: 0;
  background-color: rgba(236, 239, 241, 0.5);
  font-family: "Noto Sans TC", "Helvetica";
  font-size: 14px;
  font-weight: 500;
}

.products {
  &__item {
    color: #212121;
    text-decoration: none !important;
    display: grid;
    border: solid 1px #ddd;
    border-radius: 8px;
    overflow: hidden;
    padding: 8px;
    background-color: white;

    &__photo {
      grid-area: photo;
      aspect-ratio: 1;
    }

    &__title {
      grid-area: title;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      font-size: 1rem;
      font-weight: 500;
    }

    &__description {
      grid-area: description;
      display: -webkit-box;
      font-size: 0.875rem;
      font-weight: 400;
      color: #aaa;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__price {
      grid-area: price;
      margin-bottom: 8px;
      font-size: 1rem;
      font-weight: 300;
    }

    &__add-to-cart {
      grid-area: cart;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      > * + * {
        margin-left: 16px;
      }

      ::v-deep .b-form-spinbutton > output {
        font-weight: 500;
        font-size: 1.1rem;
      }
    }
  }

  &.grid-list {
    padding: 0 4px;

    @media screen and (min-width: 425px) {
      padding: 0 16px;
    }

    .products__item {
      grid-template-columns: 1fr 1fr;
      grid-template-rows: min-content min-content min-content 1fr;
      gap: 0 8px;
      grid-template-areas:
        "photo title"
        "photo price"
        "photo description"
        "photo cart";
      margin-bottom: 4px;

      @media screen and (min-width: 425px) {
        margin-bottom: 16px;
      }

      &__photo {
        height: calc(100% + 16px);
        margin: -8px 0 -8px -8px;

        img {
          height: 100%;
          object-fit: cover;
        }
      }

      &__title {
        -webkit-line-clamp: 1;
      }

      &__description {
        -webkit-line-clamp: 1;
      }

      &__add-to-cart {
        margin-top: 16px;
      }
    }
  }

  &.grid-1 {
    grid-template-columns: 1fr;
    display: grid;
    gap: 1rem;
    padding: 0 16px;

    .products__item {
      gap: 0px;
      grid-template-areas:
        "photo"
        "title"
        "price"
        "description"
        "cart";

      &__title {
        text-align: center;
      }

      &__photo {
        margin: -8px -8px 8px;
      }

      &__price {
        text-align: center;
      }

      &__add-to-cart {
        margin-top: 16px;
      }
    }
  }

  &.grid-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 4px;
    padding: 4px;

    @media screen and (min-width: 512px) {
      gap: 16px;
      padding: 16px;
    }

    .products__item {
      height: 100%;
      grid-template-columns: 100%;
      grid-template-rows: min-content min-content min-content auto;
      gap: 0px;
      grid-template-areas:
        "photo"
        "title"
        "price"
        "description"
        "cart";

      &__title {
        text-align: center;
      }

      &__price {
        text-align: center;
      }

      &__photo {
        margin: -8px -8px 8px;
      }

      &__add-to-cart {
        margin-top: 16px;
      }
    }
  }
}

.filters {
  .filter {
    position: relative;
    background-color: #f5f7f8;
    border-color: #f5f7f8;
    font-size: 14px;
    font-weight: 500;
    &:focus,
    &:active,
    &.active {
      background-color: #90a4ae;
      border-color: #90a4ae;
      color: white;
      &::after {
        content: url("./images/icon-check.svg");
        position: absolute;
        right: 10px;
        fill: black;
      }
    }
  }
}

.footer-action-bar {
  max-width: 768px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  background-color: white;
  box-shadow: 0px -2px 4px 0px #0000000D;

  &__summary {
    display: flex;
    align-items: center;
  }

  &__text {
    font-size: .875rem;
    color: #777;
    text-wrap: nowrap;
  }

  &__text + &__text {
    margin-left: 16px;
  }

  &__text--emphasis {
    font-size: 1rem;
    font-weight: 500;
    color: black;
  }

  &__text--big {
    font-size: 1.25rem;
  }

  .btn {
    border-width: 2px;
    padding: 0.8rem;
    font-weight: 500;
    border-radius: 0.5rem;

    &.btn-primary {
      background-color: var(--s-primary);
      border-color: var(--s-primary);
    }
  }

  &__checkout-button {
    flex: 0 1 50%;
    margin-left: 16px;
  }
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  left: 0;
  right: 0;
}

.spinner-positioner {
  flex: 1 1 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
